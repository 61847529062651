import React from "react"
import { Link } from "gatsby"
import { IntercomAPI } from "react-intercom"
import Newsletter from "../components/newsletter"

const routes = require("../../../types/routes")

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
    this.date = new Date()
  }

  onClick() {
    IntercomAPI("show")
  }

  render() {
    return (
      <div className="footer">
        <div className={"container"}>
          <div className={"row"}>
            <div className={"column"}>
              <div className={"column-section  flex-large"}>
                <div className={"logo"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120.379"
                    height="15"
                    viewBox="0 0 120.379 15"
                  >
                    <g transform="translate(-68 -42.444)">
                      <path
                        d="M20.813,14.946a7.39,7.39,0,0,1-5.055-1.928c-.12-.12-.12-.12-.12-.241s0-.241.12-.241l1.2-1.326c.12-.12.12-.12.241-.12s.241,0,.241.12a5.066,5.066,0,0,0,3.129,1.205,4.787,4.787,0,0,0,4.574-4.821,4.787,4.787,0,0,0-4.574-4.821,4.808,4.808,0,0,0-3.129,1.205.366.366,0,0,1-.481,0L15.638,2.652c-.12-.121-.12-.121-.12-.241s0-.241.12-.241A7.19,7.19,0,0,1,20.813,0a7.473,7.473,0,0,1,0,14.946Z"
                        transform="translate(64.654 42.498)"
                        fill="#23afff"
                      />
                      <path
                        className="flip"
                        d="M34.107.669V.308h.963l8.546,8.919V.308h2.768V14.771h-.963L36.876,5.49h-.12v9.281H34.107Z"
                        transform="translate(60.628 42.432)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M54.959,2.718H51.468V.308h9.749V2.718H57.727V14.771H54.959Z"
                        transform="translate(56.868 42.432)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M65.6.308h2.768V14.771H65.6Z"
                        transform="translate(53.807 42.432)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M97.22,0a8.011,8.011,0,0,1,5.055,1.928c.121.121.12.121.12.241s0,.241-.12.241l-1.324,1.326c-.121.121-.121.121-.241.121s-.241,0-.241-.121A4.807,4.807,0,0,0,97.34,2.531a4.89,4.89,0,0,0-4.694,4.821,4.628,4.628,0,0,0,4.694,4.7,7.767,7.767,0,0,0,2.407-.482V8.678H97.7V6.268h4.814v6.991a.442.442,0,0,1-.121.362,11.01,11.01,0,0,1-5.175,1.326,7.4,7.4,0,0,1-7.342-7.473A7.4,7.4,0,0,1,97.22,0Z"
                        transform="translate(48.55 42.498)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M108.775.308h9.268V2.718h-6.5V6.334h5.417V8.745h-5.417v3.5h6.5v2.531h-9.268Z"
                        transform="translate(44.458 42.432)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M7.459,14.93A7.486,7.486,0,0,0,14.8,8.663H12.032a4.846,4.846,0,0,1-5.176,3.5A4.928,4.928,0,0,1,2.644,7.337a4.793,4.793,0,0,1,4.092-4.7,4.728,4.728,0,0,1,5.176,3.5H14.68A7.4,7.4,0,0,0,0,7.819,7.607,7.607,0,0,0,7.459,14.93Z"
                        transform="translate(68.019 42.514)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M71.748.669V.308h.963l8.546,8.919V.308h2.768V14.771h-.963L74.4,5.49v9.281H71.748Z"
                        transform="translate(52.476 42.432)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M123.216.669V.308h.963l8.547,8.919h.12V.308h2.648V14.771h-.963L125.985,5.49v9.281h-2.769Z"
                        transform="translate(41.33 42.432)"
                        fill="#fff"
                      />
                      <path
                        className="flip"
                        d="M144.649,2.718h-3.611V.308h9.87V2.718H147.3V14.771h-2.648Z"
                        transform="translate(37.471 42.432)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </div>
                <p className={"description"}>
                  Reduce the burden of collecting, verifying and monitoring
                  compliance in your Supply Chain.
                </p>
                <div className={"country"}>
                  <div className={"icon"}>
                    <img src={"/assets/images/icons/us.svg"} />
                  </div>
                  <p className={"value"}>US: +1-202-329-124</p>
                </div>
                <div className={"country"}>
                  <div className={"icon"}>
                    <img src={"/assets/images/icons/uk.svg"} />
                  </div>
                  <p className={"value"}>UK: +44-207-157-9836</p>
                </div>
              </div>
              <div className={"column-section large-section"}>
                <h5 className={"column-header"}>Product</h5>
                <Link
                  to={routes.routes.operationalResilience}
                  className={"column-item"}
                >
                  Operational
                  <br />
                  resilience
                </Link>
                <Link to={routes.routes.procurement} className={"column-item"}>
                  Procurement
                </Link>
                <Link to={routes.routes.compliance} className={"column-item"}>
                  Compliance
                </Link>
              </div>
              <div className={"column-section"}>
                <h5 className={"column-header"}>Company</h5>
                <Link to={routes.routes.company} className={"column-item"}>
                  About us
                </Link>
                <Link to={routes.routes.contactUs} className={"column-item"}>
                  Contact us
                </Link>
                <Link to={routes.routes.blog} className={"column-item"}>
                  Blog
                </Link>
                <Link to={routes.routes.getStarted} className={"column-item"}>
                  Request a demo
                </Link>
              </div>
              <div className={"column-section"}>
                <h5 className={"column-header"}>Resources</h5>
                <Link
                  to={routes.routes.privacyPolicy}
                  className={"column-item"}
                >
                  Privacy policy
                </Link>
                <Link to={routes.routes.cookiePolicy} className={"column-item"}>
                  Cookie policy
                </Link>
                <Link
                  to={routes.routes.termsAndConditions}
                  className={"column-item"}
                >
                  Terms of use
                </Link>
              </div>
              <div className={"column-section newsletter"}>
                <h5 className={"column-header"}>Join our newsletter</h5>
                <p className={"column-item"}>
                  Get regular updates on how to achieve operational resilience
                  for your enterprise
                </p>
                <Newsletter mode={"light"} />
              </div>
            </div>
          </div>
        </div>
        <div className={"sub-footer"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"group"}>
                  <p className={"text"}>
                    © Contingent {this.date.getFullYear()}
                  </p>
                </div>
                <div className={"group"}>
                  <a
                    href={"https://www.linkedin.com/company/contingent-ai/"}
                    target={"_blank"}
                    className={"group hover"}
                  >
                    <div className={"icon"}>
                      <img src={"/assets/images/icons/linkedin.svg"} />
                    </div>
                    <p className={"text"}>Follow us on Linkedin</p>
                  </a>
                  <a
                    href={"https://twitter.com/contingenttech/"}
                    target={"_blank"}
                    className={"group hover"}
                  >
                    <div className={"icon"}>
                      <img src={"/assets/images/icons/twitter.svg"} />
                    </div>
                    <p className={"text"}>Follow us on Twitter</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
